import actions from './actions';

const { FORM_FIELD_TABLE_READ_BEGIN, FORM_FIELD_TABLE_READ_SUCCESS, FORM_FIELD_TABLE_READ_ERR } = actions;

const initialState = {
  tableData: [],
  loading: false,
  error: null,
};

const formDataTableReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FORM_FIELD_TABLE_READ_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case FORM_FIELD_TABLE_READ_SUCCESS:
      return {
        ...initialState,
        tableData: data,
        loading: false,
      };
    case FORM_FIELD_TABLE_READ_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export default formDataTableReducer;
