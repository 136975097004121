// API PATH

// DEV
export const apiPath = 'https://dimp.api.comclark.tech/';

// NO RACK
// export const apiPath = 'https://dpd-api.bir.gov.ph/';
// export const apiPath = 'http://172.16.130.93/';

// QC 7A
// export const apiPath = 'http://172.19.218.251/';

// QC 7B
// export const apiPath = 'http://172.19.232.251/';

// BAGUIO
// export const apiPath = 'http://172.17.182.6/';

// CALOOCAN
// export const apiPath = 'http://172.19.211.251/';

// SAN FERNANDO
// export const apiPath = 'http://172.19.216.6/';

// MAKATI 8A
// export const apiPath = 'http://172.17.215.251/';

// MAKATI 8B
// export const apiPath = 'http://172.19.234.251/';

// CABAMIRO
// export const apiPath = 'http://172.17.184.251/';

// LAQUEMAR
// export const apiPath = 'http://172.19.220.251/';

// MANILA
// export const apiPath = 'http://172.17.214.251/';
