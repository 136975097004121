const actions = {
  PSOC_READ_BEGIN: 'PSOC_READ_BEGIN',
  PSOC_READ_SUCCESS: 'PSOC_READ_SUCCESS',
  PSOC_READ_ERR: 'PSOC_READ_ERR',

  psocReadBegin: () => {
    return {
      type: actions.PSOC_READ_BEGIN,
    };
  },

  psocReadSuccess: (data) => {
    return {
      type: actions.PSOC_READ_SUCCESS,
      data,
    };
  },

  psocReadErr: (err) => {
    return {
      type: actions.PSOC_READ_ERR,
      err,
    };
  },
};

export default actions;
