import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const UploadDocumentList = lazy(() => import('../../container/operations/uploadDocument/List'));
const UploadBatchHeader = lazy(() => import('../../container/operations/uploadDocument/BatchHeader'));
const UploadFiles = lazy(() => import('../../container/operations/uploadDocument/Files'));
const Search = lazy(() => import('../../container/search/Search'));
const SearchView = lazy(() => import('../../container/search/SearchView'));
const QAForm = lazy(() => import('../../container/qaForm/QAForm'));
const QAFormReview = lazy(() => import('../../container/qaForm/QAReview'));
const QAFormView = lazy(() => import('../../container/qaForm/QAView'));
const ReportGeneration = lazy(() => import('../../container/reportGeneration/ReportGeneration'));
const Variables = lazy(() => import('../../container/operations/settings/Variables'));
const Operations = lazy(() => import('../../container/operations/settings/OperationsManagement'));
const DataResources = lazy(() => import('../../container/operations/settings/dataResource/DataResources'));
const DataFields = lazy(() => import('../../container/operations/settings/dataResource/DataFields'));
const ViewDataFields = lazy(() => import('../../container/operations/settings/dataResource/ViewDataFields'));
const UserManagement = lazy(() => import('../../container/operations/settings/userManagement/UserManagement'));
const AddUser = lazy(() => import('../../container/operations/settings/userManagement/AddUser'));
const ViewUser = lazy(() => import('../../container/operations/settings/userManagement/ViewUser'));
const UserProfile = lazy(() => import('../../container/profile/profile'));

function PagesRoute() {
  return (
    <Routes>
      <Route path="upload" element={<UploadDocumentList />} />
      <Route path="upload/batch-header" element={<UploadBatchHeader />} />
      <Route path="upload/files" element={<UploadFiles />} />
      <Route path="search" element={<Search />} />
      <Route path="search/view" element={<SearchView />} />
      <Route path="index-form" element={<QAForm />} />
      <Route path="index-form/review" element={<QAFormReview />} />
      <Route path="index-form/view" element={<QAFormView />} />
      <Route path="report-generation" element={<ReportGeneration />} />
      <Route path="variables" element={<Variables />} />
      <Route path="operations" element={<Operations />} />
      <Route path="document-info-mngmt" element={<DataResources />} />
      <Route path="document-info-mngmt/view" element={<DataFields />} />
      <Route path="document-info-mngmt/view-data-field" element={<ViewDataFields />} />
      <Route path="user-mngmt" element={<UserManagement />} />
      <Route path="user-mngmt/add-user" element={<AddUser />} />
      <Route path="document-info-mngmt/user-view" element={<ViewUser />} />
      <Route path="profile" element={<UserProfile />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PagesRoute;
