import actions from './actions';

const { DATA_RESOURCES_READ_BEGIN, DATA_RESOURCES_READ_SUCCESS, DATA_RESOURCES_READ_ERR } = actions;

const initialState = {
  tableData: [],
  loading: false,
  error: null,
};

const dataresourcesReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case DATA_RESOURCES_READ_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case DATA_RESOURCES_READ_SUCCESS:
      return {
        ...initialState,
        tableData: data,
        loading: false,
      };
    case DATA_RESOURCES_READ_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export default dataresourcesReducer;
