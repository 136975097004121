import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage';
import rootReducer from './rootReducers';
import auth from './authentication/reducers';

const persistConfig = {
  key: 'root',
  whitelist: ['auth'],
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer)
// (process.env.NODE_ENV === "development" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose
// const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk.withExtraArgument())));
const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk.withExtraArgument())));

export const persistor = persistStore(store)

export default store;
