import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const Pricing = lazy(() => import('../../container/pages/PricingTable'));
const Maintenance = lazy(() => import('../../container/pages/Maintenance'));
const Faq = lazy(() => import('../../container/pages/Faq'));
const SearchResult = lazy(() => import('../../container/pages/SearchResult'));
const ComingSoon = lazy(() => import('../../container/pages/ComingSoon'));
const TermsCondition = lazy(() => import('../../container/pages/TermsConditions'));
const Wizards = lazy(() => import('../../container/pages/wizards/Wizards'));
const BlogOne = lazy(() => import('../../container/pages/blog/BlogOne'));
const BlogTwo = lazy(() => import('../../container/pages/blog/BlogTwo'));
const BlogThree = lazy(() => import('../../container/pages/blog/BlogThree'));
const BlogDetails = lazy(() => import('../../container/pages/blog/BlogDetails'));
const BlankPage = lazy(() => import('../../container/pages/BlankPage'));
const Settings = lazy(() => import('../../container/profile/settings/Settings'));
const ChangeLog = lazy(() => import('../../container/pages/ChangeLog'));
const Banners = lazy(() => import('../../container/pages/Banners'));
const Testimonials = lazy(() => import('../../container/pages/Testimonials'));

const UploadDocument = lazy(() => import('../../container/upload/UploadDocument'));
const UploadDocumentList = lazy(() => import('../../container/operations/uploadDocument/List'));
const UploadBatchHeader = lazy(() => import('../../container/operations/uploadDocument/BatchHeader'));
const UploadFiles = lazy(() => import('../../container/operations/uploadDocument/Files'));
const Search = lazy(() => import('../../container/search/Search'));
const SearchView = lazy(() => import('../../container/search/SearchView'));
const QAForm = lazy(() => import('../../container/qaForm/QAForm'));
const QAFormReview = lazy(() => import('../../container/qaForm/QAReview'));
const QAFormView = lazy(() => import('../../container/qaForm/QAView'));
const ReportGeneration = lazy(() => import('../../container/reportGeneration/ReportGeneration'));
const Variables = lazy(() => import('../../container/operations/settings/Variables'));
const Operations = lazy(() => import('../../container/operations/settings/OperationsManagement'));
const DataResources = lazy(() => import('../../container/operations/settings/dataResource/DataResources'));
const DataFields = lazy(() => import('../../container/operations/settings/dataResource/DataFields'));
const UserManagement = lazy(() => import('../../container/operations/settings/userManagement/UserManagement'));
const AddUser = lazy(() => import('../../container/operations/settings/userManagement/AddUser'));
const ViewUser = lazy(() => import('../../container/operations/settings/userManagement/ViewUser'));
const UserProfile = lazy(() => import('../../container/profile/profile'));

function PagesRoute() {
  return (
    <Routes>
      {/* <Route path="upload" element={<UploadDocumentList />} />
      <Route path="upload/batch-header" element={<UploadBatchHeader />} />
      <Route path="upload/files" element={<UploadFiles />} /> */}
      <Route path="search" element={<Search />} />
      <Route path="search/view" element={<SearchView />} />
      {/* <Route path="index-form" element={<QAForm />} />
      <Route path="index-form/review" element={<QAFormReview />} />
      <Route path="index-form/view" element={<QAFormView />} /> */}
      <Route path="report-generation" element={<ReportGeneration />} />
      {/* <Route path="variables" element={<Variables />} />
      <Route path="operations" element={<Operations />} />
      <Route path="document-info-mngmt" element={<DataResources />} />
      <Route path="document-info-mngmt/view" element={<DataFields />} /> */}
      <Route path="user-mngmt" element={<UserManagement />} />
      <Route path="document-info-mngmt/user-view" element={<ViewUser />} />
      <Route path="profile" element={<UserProfile />} />
      {/* <Route path="user-mngmt/add-user" element={<AddUser />} /> */}

      <Route path="changelog" element={<ChangeLog />} />
      <Route path="settings/*" element={<Settings />} />
      <Route path="Pricing" element={<Pricing />} />
      <Route path="banners" element={<Banners />} />
      <Route path="testimonials" element={<Testimonials />} />
      <Route path="faq" element={<Faq />} />
      <Route path="searchResult" element={<SearchResult />} />
      <Route path="starter" element={<BlankPage />} />
      <Route path="comingSoon" element={<ComingSoon />} />
      <Route path="termCondition" element={<TermsCondition />} />
      <Route path="wizards/*" element={<Wizards />} />
      <Route path="blog/blogone" element={<BlogOne />} />
      <Route path="blog/blogtwo" element={<BlogTwo />} />
      <Route path="blog/blogthree" element={<BlogThree />} />
      <Route path="blog/details" element={<BlogDetails />} />
      <Route path="*" element={<NotFound />} />
      <Route path="maintenance" element={<Maintenance />} />
    </Routes>
  );
}

export default PagesRoute;
