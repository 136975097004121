const actions = {
  GENSTATUS_READ_BEGIN: 'GENSTATUS_READ_BEGIN',
  GENSTATUS_READ_SUCCESS: 'GENSTATUS_READ_SUCCESS',
  GENSTATUS_READ_ERR: 'GENSTATUS_READ_ERR',

  GenStatusReadBegin: () => {
    return {
      type: actions.GENSTATUS_READ_BEGIN,
    };
  },

  GenStatusReadSuccess: (data) => {
    return {
      type: actions.GENSTATUS_READ_SUCCESS,
      data,
    };
  },

  GenStatusReadErr: (err) => {
    return {
      type: actions.GENSTATUS_READ_ERR,
      err,
    };
  },
};

export default actions;
