import axios from 'axios';
import Cookies from 'js-cookie';
import { apiPath } from '../constants/defaultValues';

export default axios.create({
  baseURL: apiPath,
  headers: {
    'Content-Type': 'application/json',
    // Authorization: `Bearer ${localStorage.getItem('dpd_token')}`,
  },
});
