import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import Dashboard from './dashboard';
import Pages from './pages';
import withRRLayout from '../../layout/withRRLayout';
import axios from '../../helpers/axios';
import { logOut } from '../../redux/authentication/actionCreator';

const NotFound = lazy(() => import('../../container/pages/404'));
const Search = lazy(() => import('../../container/search/Search'));

const RR = React.memo(() => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState('Active');
  const [timeOut, setTimeout] = useState('900000');
  const [count, setCount] = useState(0);
  const [remaining, setRemaining] = useState(0);

  const onIdle = () => {
    setState('Idle');
    dispatch(logOut(() => navigate('/')));
  };

  const onActive = () => {
    setState('Active');
  };

  const onAction = () => {
    setCount(count + 1);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: timeOut,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    // axios.get(`/api/get_system_timeout`).then((res) => {
    //   setTimeout(res.data.value);
    // });

    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense
      fallback={
        <div className="spin flex items-center justify-center bg-white dark:bg-dark h-screen w-full fixed z-[999] ltr:left-0 rtl:right-0 top-0">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route index path="/*" element={<Dashboard />} />
        <Route path="pages/*" element={<Pages />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default withRRLayout(RR);
