const actions = {
  TAXTYPE_READ_BEGIN: 'TAXTYPE_READ_BEGIN',
  TAXTYPE_READ_SUCCESS: 'TAXTYPE_READ_SUCCESS',
  TAXTYPE_READ_ERR: 'TAXTYPE_READ_ERR',

  TaxTypeReadBegin: () => {
    return {
      type: actions.TAXTYPE_READ_BEGIN,
    };
  },

  TaxTypeReadSuccess: (data) => {
    return {
      type: actions.TAXTYPE_READ_SUCCESS,
      data,
    };
  },

  TaxTypeReadErr: (err) => {
    return {
      type: actions.TAXTYPE_READ_ERR,
      err,
    };
  },
};

export default actions;
