const actions = {

  FORM_FIELD_TABLE_READ_BEGIN: 'FORM_FIELD_TABLE_READ_BEGIN',
  FORM_FIELD_TABLE_READ_SUCCESS: 'FORM_FIELD_TABLE_READ_SUCCESS',
  FORM_FIELD_TABLE_READ_ERR: 'FORM_FIELD_TABLE_READ_ERR',

  formFieldTableReadBegin: () => {
      return {
        type: actions.FORM_FIELD_TABLE_READ_BEGIN
      }
  },

  formFieldTableReadSuccess: (data) => {
    return {
      type: actions.FORM_FIELD_TABLE_READ_SUCCESS,
      data
    }
  },

  formFieldTableReadErr: (err) => {
    return {
      type: actions.FORM_FIELD_TABLE_READ_ERR,
      err
    }
  },
};

export default actions;
