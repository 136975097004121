const actions = {
  PSIC_READ_BEGIN: 'PSIC_READ_BEGIN',
  PSIC_READ_SUCCESS: 'PSIC_READ_SUCCESS',
  PSIC_READ_ERR: 'PSIC_READ_ERR',

  psicReadBegin: () => {
    return {
      type: actions.PSIC_READ_BEGIN,
    };
  },

  psicReadSuccess: (data) => {
    return {
      type: actions.PSIC_READ_SUCCESS,
      data,
    };
  },

  psicReadErr: (err) => {
    return {
      type: actions.PSIC_READ_ERR,
      err,
    };
  },
};

export default actions;
