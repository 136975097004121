import { Menu } from 'antd';
import React from 'react';
import axios from '../helpers/axios';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import '../static/css/custom.css';
import {
  UilDashboard,
  UilUpload,
  UilSearch,
  UilFileShieldAlt,
  UilListUl,
  UilSetting,
  UilEllipsisV,
  UilUsersAlt,
} from '@iconscout/react-unicons';
import propTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import { NavTitle } from './Style';
import { getUserRole } from '../utility/utility';

function MenuItems({ toggleCollapsed }) {
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu, login } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
      login: state.auth.login,
    };
  });

  let role = getUserRole(login);
  const path = `/${role}`;
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'search'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  if (login) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${login.key}`;
  }

  const items = [
    getItem(
      !topMenu && (
        <NavTitle className="flex text-[12px] font-medium uppercase text-light mt-[20px] dark:text-white87 p-0">
          DPD Portal
        </NavTitle>
      ),
      'dpd',
      null,
      null,
      'group',
    ),
    getItem(
      <NavLink className="text-body hover:text-blue-600" onClick={toggleCollapsed} to={path}>
        Dashboard
      </NavLink>,
      'dashboard',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={path}>
          <UilDashboard />
        </NavLink>
      ),
    ),
    (role === 'uploader' || role === 'superadmin') &&
      getItem(
        <NavLink className="text-body hover:text-blue-600" onClick={toggleCollapsed} to={`${path}/pages/upload`}>
          Upload Scanned Document
        </NavLink>,
        'upload',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/pages/upload`}>
            <UilUpload />
          </NavLink>
        ),
      ),
    role !== 'uploader' &&
      getItem(
        <NavLink className="text-body hover:text-blue-600" onClick={toggleCollapsed} to={`${path}/pages/search`}>
          Tax Return Inquiry
        </NavLink>,
        'search',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/pages/search`}>
            <UilSearch />
          </NavLink>
        ),
      ),
    (role === 'verifier' || role === 'superadmin') &&
      getItem(
        <NavLink className="text-body hover:text-blue-600" onClick={toggleCollapsed} to={`${path}/pages/index-form`}>
          QA Form
        </NavLink>,
        'forms',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/pages/index-form`}>
            <UilFileShieldAlt />
          </NavLink>
        ),
      ),
    (role === 'dpd' || role === 'no' || role === 'superadmin') &&
      getItem(
        <NavLink
          className="text-body hover:text-blue-600"
          onClick={toggleCollapsed}
          to={`${path}/pages/report-generation`}
        >
          Report Management
        </NavLink>,
        'report-generation',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/pages/report-generation`}>
            <UilListUl className="active:text-primary focus:text-primary" />
          </NavLink>
        ),
      ),
    role === 'dpd' &&
      getItem(
        <NavLink className="text-body hover:text-blue-600" onClick={toggleCollapsed} to={`${path}/pages/user-mngmt`}>
          User Management
        </NavLink>,
        'user-mngmt',
        !topMenu && (
          <NavLink className="menuItem-iocn" to={`${path}/pages/user-mngmt`}>
            <UilUsersAlt className="active:text-primary focus:text-primary" />
          </NavLink>
        ),
      ),
    (role === 'no' || role === 'superadmin') &&
      getItem(
        <NavLink className="text-body hover:text-blue-600">Systems Administration</NavLink>,
        'settings',
        !topMenu && <UilSetting />,
        [
          getItem(
            <NavLink className="text-body hover:text-blue-600" onClick={toggleCollapsed} to={`${path}/pages/variables`}>
              Variable Management
            </NavLink>,
            'variable-mngmt',
            null,
          ),
          getItem(
            <NavLink
              className="text-body hover:text-blue-600"
              onClick={toggleCollapsed}
              to={`${path}/pages/operations`}
            >
              Operations Management
            </NavLink>,
            'operations-mngmt',
            null,
          ),
          getItem(
            <NavLink
              className="text-body hover:text-blue-600"
              onClick={toggleCollapsed}
              to={`${path}/pages/user-mngmt`}
            >
              User Management
            </NavLink>,
            'user-mngmt',
            null,
          ),
          getItem(
            <NavLink
              className="text-body hover:text-blue-600"
              onClick={toggleCollapsed}
              to={`${path}/pages/document-info-mngmt`}
            >
              Document Info Management
            </NavLink>,
            'document-info-mngmt',
            null,
          ),
          // getItem(
          //   <NavLink
          //     className="text-body hover:text-blue-600"
          //     onClick={toggleCollapsed}
          //     to={`${path}/pages/scheduled-jobs`}
          //   >
          //     Scheduled Jobs
          //   </NavLink>,
          //   'scheduled_jobs',
          //   null,
          // ),
        ],
      ),
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'search'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
