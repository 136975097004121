/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
  return `${text.split(' ').slice(0, size).join(' ')}...`;
};

const idGenerator = (events, length = 1) => {
  const arrayData = [];
  events.map((data) => {
    return arrayData.push(parseInt(data.id, 10));
  });
  const number = (Math.max(...arrayData) + 1).toString();
  return number.length < length ? `${'0'.repeat(length - number.length)}${number}` : number;
};

const isEmpty = (obj) => {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }
  return true;
};

const capitalize = (label) => {
  const string = label.replace(/_/g, ' ');
  const newString = string.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
  return newString;
};

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
      let encoded = reader.result;
      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
      return resolve(encoded);
    };
    reader.onerror = (error) => reject(error);
  });
};

const getUserRole = (data) => {
  let userRole;
  const officeTypeId = data.office_type_id;
  const roleId = data.role_id;

  if (officeTypeId === 1) {
    userRole = 'no';
  } else if (officeTypeId === 2 && roleId === 1) {
    userRole = 'superadmin';
  } else if (officeTypeId === 2 && roleId === 4) {
    userRole = 'rr';
  } else if (officeTypeId === 3 && roleId === 4) {
    userRole = 'dpd';
  } else if (officeTypeId === 2 && roleId === 6) {
    userRole = 'verifier';
  } else if (officeTypeId === 2 && roleId === 7) {
    userRole = 'uploader';
  }

  return userRole;
};

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export { ellipsis, idGenerator, isEmpty, getUserRole, capitalize, b64toBlob, getBase64 };
