const actions = {
  USER_READ_BEGIN: 'USER_READ_BEGIN',
  USER_READ_SUCCESS: 'USER_READ_SUCCESS',
  USER_READ_ERR: 'USER_READ_ERR',

  UserReadBegin: () => {
    return {
      type: actions.USER_READ_BEGIN,
    };
  },

  UserReadSuccess: (data) => {
    return {
      type: actions.USER_READ_SUCCESS,
      data,
    };
  },

  UserReadErr: (err) => {
    return {
      type: actions.USER_READ_ERR,
      err,
    };
  },
};

export default actions;
