import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const Search = lazy(() => import('../../container/search/Search'));
const SearchView = lazy(() => import('../../container/search/SearchView'));
const QAForm = lazy(() => import('../../container/qaForm/QAForm'));
const QAFormReview = lazy(() => import('../../container/qaForm/QAReview'));
const QAFormView = lazy(() => import('../../container/qaForm/QAView'));
const UserProfile = lazy(() => import('../../container/profile/profile'));
const ReportGeneration = lazy(() => import('../../container/reportGeneration/ReportGeneration'));
const Variables = lazy(() => import('../../container/operations/settings/Variables'));
const Operations = lazy(() => import('../../container/operations/settings/OperationsManagement'));
const DataResources = lazy(() => import('../../container/operations/settings/dataResource/DataResources'));
const DataFields = lazy(() => import('../../container/operations/settings/dataResource/DataFields'));
const UserManagement = lazy(() => import('../../container/operations/settings/userManagement/UserManagement'));
const AddUser = lazy(() => import('../../container/operations/settings/userManagement/AddUser'));
const ViewUser = lazy(() => import('../../container/operations/settings/userManagement/ViewUser'));

function PagesRoute() {
  return (
    <Routes>
      <Route path="search" element={<Search />} />
      <Route path="search/view" element={<SearchView />} />
      <Route path="index-form" element={<QAForm />} />
      <Route path="index-form/review" element={<QAFormReview />} />
      <Route path="index-form/view" element={<QAFormView />} />
      <Route path="profile" element={<UserProfile />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PagesRoute;
