import React, { useEffect, useState, lazy } from 'react';
import Cookies from 'js-cookie';
import { Provider, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import store, { persistor } from './redux/store';
import config from './config/config';
import ProtectedRoute from './components/utilities/protectedRoute';
import Auth from './routes/auth';
import Superadmin from './routes/superadmin';
import Dpd from './routes/dpd';
import NO from './routes/no';
import RR from './routes/rr';
import Uploader from './routes/uploader';
import Verifier from './routes/verifier';
import './static/css/style.css';
import './static/css/global.css';
import 'antd/dist/antd.less';

const NotFound = lazy(() => import('./container/pages/404'));

const { theme } = config;

function ProviderConfig() {
  const { rtl, data, topMenu, mainContent } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      mainContent: state.ChangeLayoutMode.mode,
      data: state.auth.login,
    };
  });

  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    return () => {
      unmounted = true;
    };
  }, [setPath]);

  let userRole;

  if (data) {
    const officeTypeId = data.office_type_id;
    const roleId = data.role_id;

    if (officeTypeId === 1) {
      userRole = 'no';
    } else if (officeTypeId === 2 && roleId === 1) {
      userRole = 'superadmin';
    } else if (officeTypeId === 2 && roleId === 4) {
      userRole = 'rr';
    } else if (officeTypeId === 3 && roleId === 4) {
      userRole = 'dpd';
    } else if (officeTypeId === 2 && roleId === 6) {
      userRole = 'verifier';
    } else if (officeTypeId === 2 && roleId === 7) {
      userRole = 'uploader';
    }
  }

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, mainContent }}>
        <Router basename={process.env.PUBLIC_URL}>
          <Routes>
            {!data ? (
              <Route path="/*" element={<Auth />} />
            ) : (
              <>
                {userRole === 'superadmin' && <Route path="/superadmin/*" element={<ProtectedRoute path="/*" Component={Superadmin} />} />}
                {userRole === 'dpd' && <Route path="/dpd/*" element={<ProtectedRoute path="/*" Component={Dpd} />} />}
                {userRole === 'no' && <Route path="/no/*" element={<ProtectedRoute path="/*" Component={NO} />} />}
                {userRole === 'rr' && <Route path="/rr/*" element={<ProtectedRoute path="/*" Component={RR} />} />}
                {userRole === 'uploader' && (
                  <Route path="/uploader/*" element={<ProtectedRoute path="/*" Component={Uploader} />} />
                )}
                {userRole === 'verifier' && (
                  <Route path="/verifier/*" element={<ProtectedRoute path="/*" Component={Verifier} />} />
                )}
                <Route path="*" element={<NotFound />} />
                {(data && path === process.env.PUBLIC_URL) || path === `${process.env.PUBLIC_URL}/` ? (
                  <Route path="/" element={<Navigate to={`/${userRole}`} />} />
                ) : null}
              </>
            )}
          </Routes>
          {/* {data && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) && (
            <Routes>
              <Route path="/" element={<Navigate to={`/${userRole}`} />} />
            </Routes>
          )} */}
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ProviderConfig />
      </PersistGate>
    </Provider>
  );
}

export default App;
