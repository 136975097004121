import actions from './actions';

const { REVENUE_DISTRICT_OFFICE_READ_BEGIN, REVENUE_DISTRICT_OFFICE_READ_SUCCESS, REVENUE_DISTRICT_OFFICE_READ_ERR } = actions;

const initialState = {
  tableData: [],
  loading: false,
  error: null,
};

const RevenueDistrictOfficeTableReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case REVENUE_DISTRICT_OFFICE_READ_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case REVENUE_DISTRICT_OFFICE_READ_SUCCESS:
      return {
        ...initialState,
        tableData: data,
        loading: false,
      };
    case REVENUE_DISTRICT_OFFICE_READ_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export default RevenueDistrictOfficeTableReducer;
