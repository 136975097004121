const actions = {
  ADD_DATA_RESOURCE_BEGIN: 'ADD_DATA_RESOURCE_BEGIN',
  ADD_DATA_RESOURCE_SUCCESS: 'ADD_DATA_RESOURCE_SUCCESS',
  ADD_DATA_RESOURCE_ERR: 'ADD_DATA_RESOURCE_ERR',

  addDRBegin: () => {
    return {
      type: actions.ADD_DATA_RESOURCE_BEGIN,
    };
  },

  addDRSuccess: (data) => {
    return {
      type: actions.ADD_DATA_RESOURCE_SUCCESS,
      data,
    };
  },

  addDRErr: (err) => {
    return {
      type: actions.ADD_DATA_RESOURCE_ERR,
      err,
    };
  },
};

export default actions;
