import Cookies from 'js-cookie';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import axios from '../../helpers/axios';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr } = actions;

const login = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await axios.post('/authentication/bir/token/', values);
      if (response.data.errors) {
        dispatch(loginErr(response.data.errors));
      } else {
        console.log(response)
        let userRole;
        const officeTypeId = response.data.office_type_id;
        const roleId = response.data.role_id;

        if (officeTypeId === 1) {
          userRole = 'no';
        } else if (officeTypeId === 2 && roleId === 1) {
          userRole = 'superadmin';
        } else if (officeTypeId === 2 && roleId === 4) {
          userRole = 'rr';
        } else if (officeTypeId === 3 && roleId === 4) {
          userRole = 'dpd';
        } else if (officeTypeId === 2 && roleId === 6) {
          userRole = 'verifier';
        } else if (officeTypeId === 2 && roleId === 7) {
          userRole = 'uploader';
        }

        localStorage.setItem('dpd_token', response.data.key);
        localStorage.setItem('dpd_user_id', response.data.user_id);
        localStorage.setItem('dpd_role_id', response.data.role_id);
        localStorage.setItem('dpd_user_role', response.data.role_name);
        Cookies.set('access_token', response.data.key, {
          secure: false,
        });
        Cookies.set('loggedIn', true);
        Cookies.set('user_id', response.data.user_id);
        Cookies.set('role_id', response.data.role_id);
        Cookies.set('user_role', response.data.role_name);
        dispatch(loginSuccess(response.data));
        callback(`/${userRole}`);
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const register = (values) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('/register', values);
      if (response.data.errors) {
        dispatch(loginErr('Registration failed!'));
      } else {
        dispatch(loginSuccess(false));
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());
    try {
      Cookies.remove('access_token');
      Cookies.remove('loggedIn');
      Cookies.remove('user_id');
      Cookies.remove('role_id');
      Cookies.remove('user_role');
      localStorage.clear();
      dispatch(logoutSuccess(false));
      callback();
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut, register };
