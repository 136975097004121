const actions = {
  REGION_READ_BEGIN: 'REGION_READ_BEGIN',
  REGION_READ_SUCCESS: 'REGION_READ_SUCCESS',
  REGION_READ_ERR: 'REGION_READ_ERR',

  RegionReadBegin: () => {
    return {
      type: actions.REGION_READ_BEGIN,
    };
  },

  RegionReadSuccess: (data) => {
    return {
      type: actions.REGION_READ_SUCCESS,
      data,
    };
  },

  RegionReadErr: (err) => {
    return {
      type: actions.REGION_READ_ERR,
      err,
    };
  },
};

export default actions;
