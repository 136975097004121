const actions = {
  CITIES_READ_BEGIN: 'CITIES_READ_BEGIN',
  CITIES_READ_SUCCESS: 'CITIES_READ_SUCCESS',
  CITIES_READ_ERR: 'CITIES_READ_ERR',

  CitiesReadBegin: () => {
    return {
      type: actions.CITIES_READ_BEGIN,
    };
  },

  CitiesReadSuccess: (data) => {
    return {
      type: actions.CITIES_READ_SUCCESS,
      data,
    };
  },

  CitiesReadErr: (err) => {
    return {
      type: actions.CITIES_READ_ERR,
      err,
    };
  },
};

export default actions;
