const actions = {
  ROLE_READ_BEGIN: 'ROLE_READ_BEGIN',
  ROLE_READ_SUCCESS: 'ROLE_READ_SUCCESS',
  ROLE_READ_ERR: 'ROLE_READ_ERR',

  RoleReadBegin: () => {
    return {
      type: actions.ROLE_READ_BEGIN,
    };
  },

  RoleReadSuccess: (data) => {
    return {
      type: actions.ROLE_READ_SUCCESS,
      data,
    };
  },

  RoleReadErr: (err) => {
    return {
      type: actions.ROLE_READ_ERR,
      err,
    };
  },
};

export default actions;
