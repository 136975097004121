const actions = {
  COUNTRY_READ_BEGIN: 'COUNTRY_READ_BEGIN',
  COUNTRY_READ_SUCCESS: 'COUNTRY_READ_SUCCESS',
  COUNTRY_READ_ERR: 'COUNTRY_READ_ERR',

  CountryReadBegin: () => {
    return {
      type: actions.COUNTRY_READ_BEGIN,
    };
  },

  CountryReadSuccess: (data) => {
    return {
      type: actions.COUNTRY_READ_SUCCESS,
      data,
    };
  },

  CountryReadErr: (err) => {
    return {
      type: actions.COUNTRY_READ_ERR,
      err,
    };
  },
};

export default actions;
