import { combineReducers } from 'redux';
import themeUsersReducer from './themeUsers/reducers';
import { readMessageReducer } from './message/reducers';
import { readNotificationReducer } from './notification/reducers';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import { teamReducer } from './team/reducers';
import { userReducer, userGroupReducer } from './users/reducers';
import { sellersReducer } from './sellers/reducers';
import { headerSearchReducer } from './headerSearch/reducers';
import orderReducer from './orders/reducers';
import galleryReducer from './gallary/reducers';
import { emailReducer, SingleEmailReducer } from './email/reducers';
import { productReducer, SingleProductReducer } from './product/reducers';
import { chatReducer, SingleChatReducer, groupChatReducer, SingleChatGroupReducer } from './chat/reducers';
import { projectReducer, SingleProjectReducer } from './project/reducers';
import { axiosCrudReducer, axiosSingleCrudReducer } from './crud/axios/reducers';
import cartData from './cart/reducers';
import Todo from './todo/reducers';
import Note from './note/reducers';
import Task from './task/reducers';
import kanbanBoardReducer from './kanban/reducers';
import Contact from './contact/reducers';
import Profile from './profile/reducers';
import Calender from './calendar/reducers';
import FileManager from './fileManager/reducers';
import tickets from './supportTickets/reducers';
import jobs from './jobs/reducers';
import dataTable from './data-filter/reducers';
import formDataTable from './form-data/reducers';
import indexFormTable from './indexForm/reducers';
// import rolesTable from './roles/reducers';
import dataresourcesTable from './dataresources/reducers';
import qaDataTable from './qaDataFilter/reducers';
import regionsTable from './settings/regions/reducers';
import usersTable from './settings/users/reducers';
import rolesTable from './settings/roles/reducers';
import departmentTable from './settings/departments/reducers';
// import provincesTable from './settings/provinces/reducers';
// import citiesTable from './settings/cities/reducers';
// import barangayTable from './settings/barangay/reducers';
import countriesTable from './settings/countries/reducers';
import provincesTable from './settings/provinces/reducers';
import citiesTable from './settings/cities/reducers';
import barangayTable from './settings/barangay/reducers';
import addDataResource from './add-data-resource/reducers';
import genStatusTable from './settings/genStatus/reducers';
import taxTypeTable from './settings/taxTypes/reducers';
import psicTable from './settings/psic/reducers';
import psocTable from './settings/psoc/reducers';
import revenueRegionsTable from './settings/revenueRegions/reducers';
import revenueDistrictOfficeTable from './settings/revenueDistrictOffices/reducers';

import actions from './authentication/actions';

const { LOGOUT_SUCCESS } = actions;

const apiReducer = combineReducers({
  themeUsers: themeUsersReducer,
  headerSearchData: headerSearchReducer,
  message: readMessageReducer,
  notification: readNotificationReducer,
  orders: orderReducer,
  sellers: sellersReducer,
  users: userReducer,
  userGroup: userGroupReducer,
  team: teamReducer,
  auth: authReducer,
  gallery: galleryReducer,
  email: emailReducer,
  emailSingle: SingleEmailReducer,
  products: productReducer,
  product: SingleProductReducer,
  chatSingle: SingleChatReducer,
  chatSingleGroup: SingleChatGroupReducer,
  chat: chatReducer,
  groupChat: groupChatReducer,
  projects: projectReducer,
  project: SingleProjectReducer,
  ChangeLayoutMode,
  cart: cartData,
  Todo,
  Note,
  AxiosCrud: axiosCrudReducer,
  Task,
  KanbanBoard: kanbanBoardReducer,
  Contact,
  Profile,
  Calender,
  FileManager,
  tickets,
  jobs,
  dataTable,
  formDataTable,
  indexFormTable,
  // rolesTable,
  dataresourcesTable,
  qaDataTable,
  regionsTable,
  usersTable,
  rolesTable,
  departmentTable,
  provincesTable,
  citiesTable,
  barangayTable,
  countriesTable,
  addDataResource,
  genStatusTable,
  taxTypeTable,
  psicTable,
  psocTable,
  revenueRegionsTable,
  revenueDistrictOfficeTable,
  SingleAxiosCrud: axiosSingleCrudReducer,
});

const rootReducers = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined;
  }
  return apiReducer(state, action);
};

export default rootReducers;
