const actions = {
  DATA_RESOURCES_READ_BEGIN: 'DATA_RESOURCES_READ_BEGIN',
  DATA_RESOURCES_READ_SUCCESS: 'DATA_RESOURCES_READ_SUCCESS',
  DATA_RESOURCES_READ_ERR: 'DATA_RESOURCES_READ_ERR',

  dataresourcesReadBegin: () => {
    return {
      type: actions.DATA_RESOURCES_READ_BEGIN,
    };
  },

  dataresourcesReadSuccess: (data) => {
    return {
      type: actions.DATA_RESOURCES_READ_SUCCESS,
      data,
    };
  },

  dataresourcesReadErr: (err) => {
    return {
      type: actions.DATA_RESOURCES_READ_ERR,
      err,
    };
  },
};

export default actions;
