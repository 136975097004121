import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const UploadDocumentList = lazy(() => import('../../container/operations/uploadDocument/List'));
const UploadBatchHeader = lazy(() => import('../../container/operations/uploadDocument/BatchHeader'));
const UploadFiles = lazy(() => import('../../container/operations/uploadDocument/Files'));
const Search = lazy(() => import('../../container/search/Search'));
const SearchView = lazy(() => import('../../container/search/SearchView'));
const ReportGeneration = lazy(() => import('../../container/reportGeneration/ReportGeneration'));
const Variables = lazy(() => import('../../container/operations/settings/Variables'));
const Operations = lazy(() => import('../../container/operations/settings/OperationsManagement'));
const DataResources = lazy(() => import('../../container/operations/settings/dataResource/DataResources'));
const DataFields = lazy(() => import('../../container/operations/settings/dataResource/DataFields'));
const UserManagement = lazy(() => import('../../container/operations/settings/userManagement/UserManagement'));
const AddUser = lazy(() => import('../../container/operations/settings/userManagement/AddUser'));
const ViewUser = lazy(() => import('../../container/operations/settings/userManagement/ViewUser'));
const UserProfile = lazy(() => import('../../container/profile/profile'));

function PagesRoute() {
  return (
    <Routes>
      <Route path="upload" element={<UploadDocumentList />} />
      <Route path="upload/batch-header" element={<UploadBatchHeader />} />
      <Route path="upload/files" element={<UploadFiles />} />
      <Route path="profile" element={<UserProfile />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PagesRoute;
