const actions = {
  INDEX_FORM_TABLE_READ_BEGIN: 'INDEX_FORM_TABLE_READ_BEGIN',
  INDEX_FORM_TABLE_READ_SUCCESS: 'INDEX_FORM_TABLE_READ_SUCCESS',
  INDEX_FORM_TABLE_READ_ERR: 'INDEX_FORM_TABLE_READ_ERR',

  indexFormTableReadBegin: () => {
    return {
      type: actions.INDEX_FORM_TABLE_READ_BEGIN,
    };
  },

  indexFormTableReadSuccess: (data) => {
    return {
      type: actions.INDEX_FORM_TABLE_READ_SUCCESS,
      data,
    };
  },

  indexFormTableReadErr: (err) => {
    return {
      type: actions.INDEX_FORM_TABLE_READ_ERR,
      err,
    };
  },
};

export default actions;
